@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'normalize.css';

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
  font-family: "Nunito Sans", serif !important;
  font-weight: 300;
}