.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0, 
              radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 50% 50%;
  background-size: 20px 20px;
  z-index: 1;
}

.faq-container {
  position: relative;
  z-index: 2;
}
